import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Card(props) {
  const [isHover, setHover] = useState(false);

  return (
    <Link
      to={'/article/' + props.id}
      className="w-full mx-2 mt-2 md:m-5 lg:flex-1 block transition-transform transform hover:scale-105"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setHover(true)}
      onBlur={() => setHover(false)}
    >
      <article className="shadow-lg h-40">
        <img
          className="absolute object-cover w-full h-full mx-auto rounded-md shadow-lg"
          src={props.image}
          alt=""
        />

        <div
          className={`absolute inset-0 bg-black transition-opacity ${
            isHover ? 'opacity-0' : 'opacity-50'
          }`}
        />

        <div className="absolute bottom-0 pl-2 w-full overflow-hidden text-white">
          <div className="flex space-x-2">
            {props.tags.map((tag) => (
              <span
                key={tag}
                className="border border-white rounded-full text-xs px-2"
              >
                {tag}
              </span>
            ))}
          </div>

          <h2 className="font-semibold text-2xl uppercase">{props.title}</h2>
        </div>
      </article>
    </Link>
  );
}

export default Card;
