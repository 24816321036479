import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import Article from './pages/Article';
import Home from './pages/Home';
import Images from './pages/Images';

function App() {
  return (
    <HashRouter>
      <Switch>
        <Route path="/article/:id">
          <Article />
        </Route>
        <Route path="/images">
          <Images />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </HashRouter>
  );
}

export default App;
