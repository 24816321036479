const images = [
  {
    src: 'images/image_1.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_2.jpg',
    width: 1,
    height: 1,
  },
  {
    src: 'images/image_3.jpg',
    width: 4,
    height: 6,
  },
  {
    src: 'images/image_4.jpg',
    width: 5,
    height: 3,
  },
  {
    src: 'images/image_5.jpg',
    width: 5,
    height: 3,
  },
  {
    src: 'images/image_6.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_7.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_8.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_9.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_10.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_11.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_12.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_13.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_14.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_15.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_16.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_17.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_18.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_19.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_20.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_21.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_22.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_23.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_24.jpg',
    width: 3,
    height: 5,
  },
  {
    src: 'images/image_25.jpg',
    width: 3,
    height: 5,
  },
];

export default images;
