import Bowser from 'bowser';
import throttle from 'lodash/throttle';
import { v4 as uuidv4 } from 'uuid';

import { date } from './utils';

const defaultCtx = {
  site: '',
  user: '',
  uuid: '',
  rootKey: '',
  fn: (key, value) => console.error('You should enable tracking'),
};

/* EXPORTS */
export function enableTracking(fn) {
  const site = getSite();
  const { user, uuid } = getUser();
  const rootKey = `users/${user}/${uuid}/${site}`;

  defaultCtx.site = site;
  defaultCtx.user = user;
  defaultCtx.uuid = uuid;
  defaultCtx.rootKey = rootKey;
  defaultCtx.fn = fn;

  trackBrowser();
  trackLocation();
  trackState();
}

export function track(key, value) {
  const { rootKey, fn } = defaultCtx;
  fn(rootKey + key, value);
}

export function trackInPage(key, value) {
  const pageKey = getPage();
  track(pageKey + key, value);
}

export function trackEvent(value) {
  track(`/events/${date(true)}`, value);
}

function getSite() {
  const site = window.location.hostname.replace('www.', '').split('.')[0];
  return site;
}

function getUser() {
  const params = new URLSearchParams(window.location.search);
  const urlUser = params.get('u');
  const urlUuid = params.get('d');

  if (urlUser !== null) {
    localStorage.setItem('user', urlUser);
  }
  if (urlUuid !== null) {
    localStorage.setItem('uuid', urlUuid);
  }

  let localUser = localStorage.getItem('user');
  let localUuid = localStorage.getItem('uuid');

  if (localUser === null) {
    localUser = 'unknown';
    localStorage.setItem('user', localUser);
  }

  if (localUuid === null) {
    localUuid = uuidv4();
    localStorage.setItem('uuid', localUuid);
  }

  return {
    user: localUser,
    uuid: localUuid,
  };
}

function getPage(str = window.location.hash) {
  const arr = str.split('#');
  if (arr.length < 2) {
    return '/pages/unknown';
  }
  return arr[1] === '/' ? '/pages/home' : '/pages' + arr[1];
}

function trackBrowser() {
  const browser = Bowser.getParser(window.navigator.userAgent).parsedResult;
  track('/browser', browser);
}

async function trackLocation() {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    if (!data) {
      return;
    }
    track('/location', {
      city: data.city,
      country: data.country,
      continent: data.continent_code,
      postal: data.postal,
      region: data.region,
      latitude: data.latitude,
      longitude: data.longitude,
      org: data.org,
      ip: data.ip,
    });
  } catch (err) {
    track('/errors/' + date(), err.message);
  }
}

function trackState() {
  /* LOAD / UNLOAD */
  const d = date();
  trackInPage(`/updates/load`, d);
  trackEvent('enter ' + getPage());

  window.addEventListener('hashchange', (e) => {
    const old = getPage(e.oldURL);
    const d = date();
    track(`${old}/updates/unload`, d);
    trackInPage(`/updates/load`, d);
    trackEvent('go ' + old + ' to ' + getPage());
  });

  window.addEventListener('beforeunload', () => {
    const d = date();
    trackInPage('/updates/leave', d);
    trackEvent('leave ' + getPage());
  });

  /* BLUR / FOCUS */
  window.addEventListener('blur', () => {
    const d = date();
    trackInPage(`/updates/blur`, d);
    trackEvent('blur ' + getPage());
  });
  window.addEventListener('focus', () => {
    const d = date();
    trackInPage(`/updates/focus`, d);
    trackEvent('focus ' + getPage());
  });

  window.addEventListener('click', (e) => {
    trackInPage(`/clicks/${date()}`, {
      id: e.target.id,
      element: e.target.localName,
      className: e.target.className,
      text: e.target.innerText,
    });
  });

  /* SCROLL */
  window.addEventListener(
    'scroll',
    throttle(() => {
      const percent = getScrollPercent();
      trackInPage(`/scrolls/${date(true)}`, `${percent}%`);
    }, 100)
  );
}

function getScrollPercent() {
  const scroll = getScroll();
  const height = getHeight();
  const viewport = getViewport();

  if (height <= viewport) {
    return 100;
  }
  const percent = scroll / (height - viewport);

  return Math.round(percent * 100);
}

function getScroll() {
  return (
    window.scrollY ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0
  );
}

function getHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.scrollHeight,
    0
  );
}

function getViewport() {
  return Math.max(document.documentElement.clientHeight, window.innerHeight, 0);
}
