import React from 'react';
import Gallery from 'react-photo-gallery';

import images from '../resources/images';

function Images() {
  return (
    <main>
      <h1>Images</h1>
      <Gallery photos={images} direction={'column'} />
    </main>
  );
}

export default Images;
