export function date(milli = false) {
  const d = new Date();
  const format =
    d.getFullYear().toString() +
    '-' +
    add0(d.getMonth() + 1) +
    '-' +
    add0(d.getDate()) +
    ' ' +
    add0(d.getHours()) +
    'h' +
    add0(d.getMinutes()) +
    ' ' +
    add0(d.getSeconds());

  if (!milli) {
    return format;
  }

  return format + ':' + d.getMilliseconds().toString();
}

export function add0(d) {
  if (d.toString().length === 2) {
    return d.toString();
  }
  return '0' + d.toString();
}
