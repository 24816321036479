import { createClient } from 'contentful';
import { useEffect, useState } from 'react';

const client = createClient({
  space: '5x1ajtc2yxur',
  accessToken: 'XLeOeIfHH_PgTQ-p69P6lTfSJ7F-Qed2jQC7lz4b9BA',
});

export const useDocuments = (contentType) => {
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .getEntries({ content_type: contentType })
      .then((documents) => {
        setDocuments(documents);
        setLoading(false);
      })
      .catch((err) => setError(err));
  }, [contentType]);

  return [documents, loading, error];
};

export const useDocument = (id) => {
  const [document, setDocument] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    client
      .getEntry(id)
      .then((document) => {
        setDocument(document);
        setLoading(false);
      })
      .catch((err) => setError(err));
  }, [id]);

  return [document, loading, error];
};
