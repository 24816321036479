import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { save } from './services/firebase';
import { enableTracking } from './services/tracking';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

enableTracking(process.env.NODE_ENV === 'development' ? console.log : save);
