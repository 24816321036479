import 'firebase/database';

import firebase from 'firebase/app';

import { date } from './utils';

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: 'AIzaSyAF-s8W-zRrsKy2b5CO_MJTnGejvzmGjhs',
    authDomain: 'dianeetvincent-d891f.firebaseapp.com',
    projectId: 'dianeetvincent-d891f',
    storageBucket: 'dianeetvincent-d891f.appspot.com',
    messagingSenderId: '656100635792',
    appId: '1:656100635792:web:a5c9c95bbdcb927699d415',
    databaseURL: 'https://dianeetvincent-d891f-default-rtdb.firebaseio.com/',
  });
}
const database = firebase.database();

export function save(key, value) {
  try {
    database.ref(key).set(value);
  } catch (err) {
    database.ref('/errors/' + date()).set(err.message);
  }
}
