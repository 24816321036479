import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { useParams } from 'react-router-dom';
import YouTube from 'react-youtube';

import { useDocument } from '../services/contentful';
import { track, trackEvent, trackInPage } from '../services/tracking';
import { date } from '../services/utils';
import Spinner from './components/Spinner';

function Article() {
  const { id } = useParams();
  const [article, loading, error] = useDocument(id);
  const [comment, setComment] = useState('');

  const onChange = (e) => {
    trackInPage(`/comments/drafts/${date(true)}`, e.target.value);
    setComment(e.target.value);
  };

  const onSubmit = () => {
    trackInPage(`/comments/submits/${date(true)}`, comment);
    setComment('');
  };

  useEffect(() => {
    if (error !== null) {
      track(`/errors/${date()}`, error);
    }
  }, [error]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="container w-full p-2 bg-gradient">
      <div className="w-full md:max-w-3xl mx-auto">
        <header className="h-60 relative">
          <img
            className="absolute inset-0 mx-auto block w-full h-full object-cover rounded-md"
            src={article.fields.image.fields.file.url}
            alt=""
          />

          <div className="absolute bottom-0 left-0 right-0 h-24 bg-black opacity-40" />

          <div className="absolute bottom-0 text-white p-2 w-full">
            <div className="flex w-full justify-between">
              <div className="flex space-x-2">
                {article.fields.tags.map((tag) => (
                  <span
                    key={tag}
                    className="border border-white rounded-full text-xs px-2"
                  >
                    {tag}
                  </span>
                ))}
              </div>
              <p className="text-xs italic capitalize">
                {new Intl.DateTimeFormat('fr-FR', {
                  dateStyle: 'full',
                  timeStyle: 'short',
                }).format(new Date(article.fields.date))}
              </p>
            </div>

            <h1 className="font-bold uppercase font-sans break-normal text-2xl md:text-2xl">
              {article.fields.title}
            </h1>

            <em>{article.fields.subtitle}</em>
          </div>
        </header>

        <section className="bg-white rounded shadow-xl my-5 p-5">
          {article.fields.videoId && (
            <YouTube
              videoId={article.fields.videoId}
              className={'max-w-full mb-10 mx-auto'}
              onReady={() => {
                trackEvent('ready video ' + article.fields.videoId);
                trackInPage('/video/ready', date());
              }}
              onPlay={() => {
                trackEvent('play video ' + article.fields.videoId);
                trackInPage('/video/play', date());
              }}
              onPause={() => {
                trackEvent('pause video ' + article.fields.videoId);
                trackInPage('/video/pause', date());
              }}
              onEnd={() => {
                trackEvent('saw video ' + article.fields.videoId);
                trackInPage('/video/end', date());
              }}
            />
          )}

          <article className="prose prose-sm sm:prose lg:prose-lg xl:prose-xl">
            <ReactMarkdown
              children={article.fields.description}
              allowDangerousHtml
            />
          </article>

          <div className="flex flex-row-reverse">
            <button
              id={'like-' + id}
              className="bg-transparent border border-gray-500 hover:border-green-500 text-xs text-gray-500 hover:text-green-500 font-bold py-2 px-4 rounded-full"
            >
              J'aime
            </button>
          </div>
        </section>

        <section className="bg-white rounded shadow-xl p-4 mb-5">
          <form>
            <h2 className="font-bold mb-5">Commentaires</h2>

            <textarea
              value={comment}
              onChange={onChange}
              className="w-full px-3 py-2 text-gray-700 border-2 rounded-lg focus:outline-none"
              rows="4"
            ></textarea>

            <button
              id={'submit-' + id}
              type="button"
              onClick={onSubmit}
              className="w-40 mx-auto font-bold rounded-full bg-gradient-to-r from-blue-500 to-blue-300 text-lg text-white py-2 px-8 block uppercase"
            >
              Envoyer
            </button>
          </form>
        </section>

        <section className="flex w-full items-center bg-white rounded shadow-xl px-4 md:px-6 py-5 mb-10">
          <img
            className="w-10 h-10 rounded-full mr-4"
            src="assets/vincent-rasquier-photo.jpg"
            alt="Avatar of Author"
          />
          <div className="flex-1 px-2">
            <p className="text-base font-bold md:text-xl leading-none mb-2">
              Vincent Rasquier
            </p>
            <p className="text-gray-600 text-xs md:text-base">
              Ingénieur R&D et Conseiller numérique du cabinet de Gabriel Attal
            </p>
          </div>
        </section>
      </div>
    </main>
  );
}

export default Article;
